import ApplicationController from './application_controller';
import { debounce } from "lodash-es";

export default class extends ApplicationController {
  static targets = ['newQuantity'];

  connect() {
    super.connect();
    this.onChangeQuantityDeb = debounce(this.onChangeQuantity, 1200);
  }

  doFullRefresh() {
    this.onChangeQuantity(
      {target: document.querySelector('[data-action*="onChangeQuantity"]')}
    )
  }

  onChangeQuantity(e) {
    this.stimulate('RemoveSeatsReflex#update_form', e.target, {
      quantity: e.target.value
    });
  }
}
