import ApplicationController from './application_controller';
import { debounce } from 'lodash-es';

export default class extends ApplicationController {
  static targets = [];

  connect() {
    super.connect();
    this.onChangeEmailsDeb = debounce(this.onChangeEmails, 1500);
  }

  doFullRefresh() {
    this.onChangeEmails(
      {target: document.querySelector('[data-action*="onChangeEmails"]')}
    );
  }

  onChangeEmails(e) {
    this.stimulate('AddMembersReflex#update_form', e.target, {
      emails: e.target.value
    });
  }
}
