import ApplicationController from './application_controller';

export default class extends ApplicationController {

  updatePayment(e) {
    e.preventDefault();
    Paddle.Checkout.open({
      override: e.currentTarget.getAttribute('href'),
      success: window.location.href
    });
  }

  confirmCancel(e) {

  }
}
