var page_url = `${location.href}`

// Open a modal by id on page load via the 'modal=modal_id' get parameter
window.addEventListener('DOMContentLoaded', (event) => {
    var requestedModalId = loadGetParams()['modal']
    if (!requestedModalId) {
        return
    }
    var modalTrigger = document.querySelector(
        '[data-target="[id=\'' + requestedModalId +'\']"]'
    )
    if (!modalTrigger) {
        return
    }

    modalTrigger.click()
});

export function handleModalOpen(clickTarget) {
    var modal = document.querySelector(clickTarget.dataset.target)
    var modalBody = modal.querySelector('.modal-body')

    if (modal && modalBody) {
        if (!modal.firstElementChild.classList.contains('modal-sm')) {
            // Update location.href to the page inside of non-dialog modals
            // so Stimulus reflex works inside the modal.
            window.history.replaceState("", location.title, clickTarget.href);
        }

        loadContentViaAjax(clickTarget.href, modalBody, modal)
    }
}

function loadContentViaAjax(url, element, modal) {
    Rails.ajax({
        url: url,
        type: "GET",
        success: function(data) {
            modal.addEventListener('hidden.bs.modal', function () {
                // Reset location.href to the parent page
                window.history.replaceState("", location.title, page_url);
            });
            if (data.body.firstChild.classList.contains('modal-content')) {
                // The loaded page wants to control the entire modal content
                element.parentElement.parentElement.innerHTML = data.body.innerHTML;
            } else {
                // Default modal content frame is used
                element.innerHTML = data.body.innerHTML;
            }
        },
        error: function () {
            // Fall back to loading the target page directly.
            location.reload()
        }
    });
}

// see https://stackoverflow.com/a/21210643
function loadGetParams() {
    var queryDict = {};
    location.search
        .substr(1)
        .split("&")
        .forEach(function(item) {
            queryDict[item.split("=")[0]] = item.split("=")[1]
        });

    return queryDict;
}

