import ApplicationController from './application_controller';
import morphdom from 'morphdom';
import { debounce } from 'lodash-es';

export default class extends ApplicationController {
  static targets = ['uuid', 'quantity', 'vatId', 'country'];

  connect() {
    super.connect();
    this.updateFormDeb = debounce(this.updateForm, 1200);
  }

  updateForm(e) {
    this.stimulate('CheckoutReflex#update_form', e.target, {
      vat_id: this.vatIdTarget.value,
      quantity: this.quantityTarget.value,
      country: this.countryTarget.value
    });
  }

  onCheckoutSuccess(event) {
    let [data] = event.detail;
    const checkout = data.checkout;
    // set current checkout uuid
    this.uuidTarget.value = checkout.uuid;
    // clear all errors
    document.querySelectorAll('.is-invalid')
      .forEach((node) => {
        node.classList.remove('is-invalid');
      });
    Paddle.Checkout.open({
      override: checkout.pay_link,
      disableLogout: true,
      title: 'Ulysses',
      message: ''
    });
  }

  onCheckoutError(event) {
    let [data] = event.detail;

    if (data.body) {
      morphdom(
        document.querySelector('#checkout-container'),
        data.body.querySelector('#checkout-container')
      );
    } else {
      console.error(data)
    }
  }
}
