import consumer from "./consumer"

consumer.subscriptions.create("AdminWebhooksChannel", {
    /* Trigger a StimulusReflex update of the admin pages containing subscription data */
    received() {
        let reflexControllerElement = document.querySelector('[data-controller="admin-subscription"]');

        if (reflexControllerElement) {
            reflexControllerElement[reflexControllerElement.dataset.controller].stimulate()
        }
    }
})
