import consumer from "./consumer"

consumer.subscriptions.create("WebhooksChannel", {
    /**
     * Trigger updates of StimulusReflex controllers
     * marked with the `supports-webhook-refresh` data attribute
     * to refresh data that was just modified by a Webhook.
     */
    received() {
        let reflexControllerElements = document.querySelectorAll('[data-supports-webhook-refresh]');
        let openModal = document.querySelector('.modal.show');

        reflexControllerElements.forEach((reflexElement) => {
            // The Reflex controller decides how to do the
            // server-side refresh via the doFullRefresh method.
            let controller = reflexElement[reflexElement.dataset.controller];

            if (openModal && !openModal.contains(reflexElement)) {
                // Only refresh once the open modal is dismissed by the user
                openModal.addEventListener('hidden.bs.modal', () => controller.doFullRefresh());
            } else {
                controller.doFullRefresh();
            }
        });
    }
})
